export enum TebligatTeslimTipi {
    kendisine = 1,
    aynı_konuttaki_yakini,
    muhtar,
    calisani,
    karakol,
}

export enum TebligatTeslimTipiLabel {
    'Kendisine'=1,
    'Aynı Konuttaki Yakını',
    'Muhtar',
    'Çalışanı',
    'Karakol',
}
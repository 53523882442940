
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import TebligatForm from "@/components/anakart/tebligat/TebligatForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {TebligatSekliLabel} from "@/enum/TebligatSekli";
import {TebligatDurumuLabel} from "@/enum/TebligatDurumu"
import {TebligatEntity} from "@/entity/TebligatEntity";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {NotTuru} from "@/enum/NotTuru";
import NotList from "@/components/lists/NotList.vue";
import TebligatMasrafList from "@/components/forms/TebligatMasrafList.vue";
import {TebligatBelgeTuruLabel} from "@/enum/TebligatBelgeTuru";
import {Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";

@Component({
  components: {TebligatMasrafList, NotList, FormWrapper, FormDialog, TebligatForm, DeleteDialog}
})
export default class TebligatList extends Mixins(SimpleInputMixin) {
  @Prop() borclu!: BorcluEntity;
  @Prop() successOnValidate !: any;

  tebligatSekli = TebligatSekliLabel;
  tebligatDurumuLabel = TebligatDurumuLabel;
  tebligatBelgeTuruLabel = TebligatBelgeTuruLabel;
  search: string = "";
  notTuru = NotTuru;
  onFly = false;


  headers = [
    {text: "Tebligat No", align: "start", sortable: true, value: "no"},
    {text: "Belge Türü", align: "start", sortable: true, value: "belge_turu"},
    {text: "Gönderim Tarihi", value: "tebligat_gonderim_tarihi"},
    {text: "Durumu", value: "tebligat_durumu"},
    {text: "Bila Tarihi", value: "bila_tarihi"},
    {text: "Tebliğ Tarihi", value: "teblig_tarihi"},
    {text: "Şekli", value: "tebligat_sekli"},
    {text: "Kesinleşme Talebi Gönderim Tarihi",align: "center", value: "kesinlesme_tarihi"},
    {text: "Kesinlesme Tarihi", value: "kesinlesme_tarihi_manuel"},
    {text: "Son Ödeme Tarihi", value: "son_odeme_tarihi"},
    {text: "İşlemler", value: "actions", sortable: false, width: 200, align: 'center'}
  ];

  localValue!: Array<TebligatEntity>;


  async allCreateTask(){
    let tasks: Array<Task> = [];
    let takip: any;

    this.localValue.forEach((takip:any) => {
      let task: Task = new Task();
      task.dosya_esas_no = this.borclu.takip.dosya_esas_no;
      task.birim_adi = this.borclu.takip.icra_dairesi.isim;
      task.dosya_id = (this.borclu.takip.id).toString();
      task.task_type_id = TaskTuru.toplu_genel_talep_hazirla;
      task.data = {
        dosya: takip.task.response.dosya,
        params: {
          "hacizParams": {},
          "digerTalepParams": [
            {
              "grupKodu": 2,
              "talepKodu": 31,
              "talepAdi": "Takibin Kesinleşmesi",
              "talepKisaAdi": "Takibin Kesinleşmesi",
              "talepMasrafi": 0,
              "className": "AvukatTalepGenelTakibiKesinlestirDVO",
              "postaMasrafId": 0,
              "dosyaDurum": "A"
            }
          ]
        },
        tebligat_id:takip.id,
        borclu: this.borclu
      };
      tasks.push(task);
    })

  }

  async createTask(index:number) {
    /* this.onFly = true;*/
    let tasks: Array<Task> = [];
    let takip: any;

      let task: Task = new Task();
      task.dosya_esas_no = this.borclu.takip.dosya_esas_no;
      task.birim_adi = this.borclu.takip.icra_dairesi.isim;
      task.dosya_id = (this.borclu.takip.id).toString();
      task.task_type_id = TaskTuru.toplu_genel_talep_hazirla;
      task.data = {
        dosya: takip.task.response.dosya,
        params: {
          "hacizParams": {},
          "digerTalepParams": [
            {
              "grupKodu": 2,
              "talepKodu": 31,
              "talepAdi": "Takibin Kesinleşmesi",
              "talepKisaAdi": "Takibin Kesinleşmesi",
              "talepMasrafi": 0,
              "className": "AvukatTalepGenelTakibiKesinlestirDVO",
              "postaMasrafId": 0,
              "dosyaDurum": "A"
            }
          ]
        },
        tebligat_id:this.localValue[index].id,
        borclu: this.borclu
      };
      tasks.push(task);
    /*this.onFly=false*/

    }



  mounted() {
    if (!this.value) {
      this.localValue = [];
    }
  }
  get tebligatNo() {
    return (index: number) => index + 1;
  }
  get isSuccessOnValidate() {
    return this.successOnValidate || this.successOnValidate === "";
  }

  load() {
    this.$emit("load");
  }

  onAdd(item: TebligatEntity) {
    if (!this.isSuccessOnValidate) {
      this.load();
    } else {
      this.localValue.push(item);
      this.input();
    }
  }

  onUpdate(item: TebligatEntity, index: number) {
    if (!this.isSuccessOnValidate) {
      this.load();
    } else {
      let newArray = [...this.localValue];
      newArray[index] = item;
      this.localValue = newArray;
      this.input();
    }
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }
}
